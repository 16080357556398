<template>
  <div class="w-full h-full relative">
    <div class="bg-white">
      <app-header class="max-w-container mx-auto" />
    </div>

    <div class="py-40 sm:px-24 lg:px-32 max-w-container mx-auto">
      <router-view />
    </div>

    <transition name="fade">
      <support-button v-if="!visible" class="support-button" @click.native="onSupport" />
    </transition>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
const AppHeader = () => import('@/components/layout/Header')
const SupportButton = () => import('@/components/SupportButton')

export default {
  name: 'DefaultLayout',

  components: { AppHeader, SupportButton },
  computed: {
    ...mapState({
      visible: state => state.slideTooltip.visible
    })
  },
  methods: {
    ...mapActions(['showTooltip']),

    onSupport () {
      this.showTooltip({ message: '' })
    }
  }
}
</script>

<style lang="scss" scoped>
  .support-button {
    bottom: 10px;
    left: 10px;
  }
</style>
